<template>
  <div class="card h-100">
    <div class="card-body d-flex flex-column">
      <div class="text-center mb-3">
        <i :class="`fas ${product.icon} fa-3x`"></i>
      </div>
      <h5 class="card-title">{{ product.name }}</h5>
      <p class="card-text">{{ product.description }}</p>
      <div class="mt-auto">
        <p class="font-weight-bold">${{ product.price.toFixed(2) }}</p>
        <button class="btn btn-primary btn-block" @click="$emit('add-to-cart', product)">Add to Cart</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['product'],
};
</script>

<style scoped>
.card {
  margin-bottom: 20px;
}
</style>