// src/data/products.js

export const products = [
    {
        id: 1,
        code: "PROD-0001",
        name: "Eco-friendly Tote Bag",
        description: "A reusable tote bag made from organic cotton.",
        price: 15.99,
        icon: "fa-shopping-bag",
    },
    {
        id: 2,
        code: "PROD-0002",
        name: "Stainless Steel Water Bottle",
        description: "Keeps your drinks cold for 24 hours.",
        price: 25.0,
        icon: "fa-water",
    },
    {
        id: 3,
        code: "PROD-0003",
        name: "Organic Cotton T-Shirt",
        description: "Comfortable t-shirt made from 100% organic cotton.",
        price: 20.0,
        icon: "fa-tshirt",
    },
    {
        id: 4,
        code: "PROD-0004",
        name: "Recycled Paper Notebook",
        description: "Notebook made from recycled paper.",
        price: 7.5,
        icon: "fa-book",
    },
    {
        id: 5,
        code: "PROD-0005",
        name: "Bamboo Sunglasses",
        description: "Stylish sunglasses with frames made from bamboo.",
        price: 30.0,
        icon: "fa-glasses",
    },
    {
        id: 6,
        code: "PROD-0006",
        name: "Fair Trade Coffee Beans",
        description: "Organic coffee beans sourced ethically.",
        price: 15.0,
        icon: "fa-coffee",
    },
    {
        id: 7,
        code: "PROD-0007",
        name: "Reusable Metal Straw Set",
        description: "Set of stainless steel straws with cleaning brush.",
        price: 10.0,
        icon: "fa-straw",
    },
    {
        id: 8,
        code: "PROD-0008",
        name: "Solar-Powered Flashlight",
        description: "Flashlight powered by solar energy.",
        price: 18.0,
        icon: "fa-lightbulb",
    },
    {
        id: 9,
        code: "PROD-0009",
        name: "Eco-friendly Phone Case",
        description: "Biodegradable phone case made from plant-based materials.",
        price: 12.0,
        icon: "fa-mobile-alt",
    },
    {
        id: 10,
        code: "PROD-0010",
        name: "Canvas Art Print",
        description: "Art print on canvas supporting local artists.",
        price: 45.0,
        icon: "fa-image",
    },
    {
        id: 11,
        code: "PROD-0011",
        name: "Charity Bracelet",
        description: "Handmade bracelet supporting our cause.",
        price: 8.0,
        icon: "fa-hand-holding-heart",
    },
    {
        id: 12,
        code: "PROD-0012",
        name: "Organic Lip Balm",
        description: "Natural lip balm made from organic ingredients.",
        price: 5.0,
        icon: "fa-kiss",
    },
    {
        id: 13,
        code: "PROD-0013",
        name: "Recycled Plastic Pen",
        description: "Pen made from recycled plastic materials.",
        price: 3.0,
        icon: "fa-pen",
    },
    {
        id: 14,
        code: "PROD-0014",
        name: "Eco-friendly Yoga Mat",
        description: "Yoga mat made from natural rubber.",
        price: 35.0,
        icon: "fa-dumbbell",
    },
    {
        id: 15,
        code: "PROD-0015",
        name: "Organic Cotton Socks",
        description: "Comfortable socks made from organic cotton.",
        price: 10.0,
        icon: "fa-socks",
    },
    {
        id: 16,
        code: "PROD-0016",
        name: "Reusable Coffee Cup",
        description: "Travel mug made from bamboo fibers.",
        price: 14.0,
        icon: "fa-mug-hot",
    },
    {
        id: 17,
        code: "PROD-0017",
        name: "Seed Planting Kit",
        description: "Grow your own herbs with this starter kit.",
        price: 12.0,
        icon: "fa-seedling",
    },
    {
        id: 18,
        code: "PROD-0018",
        name: "Fair Trade Chocolate",
        description: "Delicious chocolate supporting fair trade farmers.",
        price: 6.0,
        icon: "fa-cookie-bite",
    },
    {
        id: 19,
        code: "PROD-0019",
        name: "Recycled Glass Vase",
        description: "Decorative vase made from recycled glass.",
        price: 22.0,
        icon: "fa-vase",
    },
    {
        id: 20,
        code: "PROD-0020",
        name: "Organic Spices Set",
        description: "Collection of organic spices for your kitchen.",
        price: 25.0,
        icon: "fa-pepper-hot",
    },
    {
        id: 21,
        code: "PROD-0021",
        name: "Eco-friendly Backpack",
        description: "Durable backpack made from recycled materials.",
        price: 50.0,
        icon: "fa-backpack",
    },
    {
        id: 22,
        code: "PROD-0022",
        name: "Handcrafted Soap Bars",
        description: "Set of natural soaps made with essential oils.",
        price: 15.0,
        icon: "fa-soap",
    },
    {
        id: 23,
        code: "PROD-0023",
        name: "Biodegradable Toothbrush",
        description: "Toothbrush with bamboo handle.",
        price: 4.0,
        icon: "fa-tooth",
    },
    {
        id: 24,
        code: "PROD-0024",
        name: "Organic Tea Selection",
        description: "Variety pack of organic teas.",
        price: 20.0,
        icon: "fa-mug-tea",
    },
    {
        id: 25,
        code: "PROD-0025",
        name: "Recycled Material Hat",
        description: "Stylish hat made from recycled fabrics.",
        price: 18.0,
        icon: "fa-hat-cowboy",
    },
    {
        id: 26,
        code: "PROD-0026",
        name: "Eco-friendly Lunchbox",
        description: "Lunchbox made from sustainable materials.",
        price: 16.0,
        icon: "fa-box",
    },
    {
        id: 27,
        code: "PROD-0027",
        name: "Charity Calendar",
        description: "2024 calendar featuring photos from our projects.",
        price: 12.0,
        icon: "fa-calendar-alt",
    },
    {
        id: 28,
        code: "PROD-0028",
        name: "Handmade Candle",
        description: "Soy candle with natural fragrances.",
        price: 10.0,
        icon: "fa-candle-holder",
    },
    {
        id: 29,
        code: "PROD-0029",
        name: "Eco-friendly Umbrella",
        description: "Umbrella made from recycled PET bottles.",
        price: 22.0,
        icon: "fa-umbrella",
    },
    {
        id: 30,
        code: "PROD-0030",
        name: "Donation Gift Card",
        description: "Gift card to donate to a cause of your choice.",
        price: 50.0,
        icon: "fa-gift",
    },
];