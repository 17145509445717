<template>
  <div>
    <div class="shopping-cart" :class="{ open: isOpen }">
      <div class="cart-header">
        <h5>Your Cart</h5>
        <button type="button" class="close" @click="closeCart">
          <span>&times;</span>
        </button>
      </div>
      <div class="cart-body">
        <div v-if="cartItems.length === 0">
          <p>Your cart is empty.</p>
        </div>
        <ul class="list-group" v-else>
          <li
              class="list-group-item"
              v-for="item in cartItems"
              :key="item.product.id"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="cart-item-info">
                <strong>{{ item.product.name }}</strong>
                <p class="mb-0">
                  ${{ item.product.price.toFixed(2) }} x {{ item.quantity }}
                </p>
              </div>
              <div class="cart-item-actions d-flex align-items-center">
                <div class="cart-item-controls">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <button class="btn btn-outline-secondary" @click="decreaseQuantity(item)">
                        -
                      </button>
                    </div>
                    <input
                        type="number"
                        class="form-control text-center"
                        :value="item.quantity"
                        @input="onQuantityChange(item, $event.target.value)"
                        min="1"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" @click="increaseQuantity(item)">
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <button
                    class="btn btn-link text-secondary p-0 remove-button"
                    @click="removeFromCart(item.product)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="cart-footer" v-if="cartItems.length > 0">
        <div class="d-flex justify-content-between">
          <strong>Total: ${{ totalPrice.toFixed(2) }}</strong>
          <button class="btn btn-primary" @click="checkout">Proceed to Checkout</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['cartItems', 'isOpen'],
  methods: {
    removeFromCart(product) {
      this.$emit('remove-from-cart', product);
    },
    checkout() {
      this.$emit('checkout');
    },
    closeCart() {
      this.$emit('close-cart');
    },
    increaseQuantity(item) {
      this.$emit('update-quantity', item.product, item.quantity + 1);
    },
    decreaseQuantity(item) {
      this.$emit('update-quantity', item.product, item.quantity - 1);
    },
    onQuantityChange(item, value) {
      const quantity = parseInt(value, 10);
      if (!isNaN(quantity) && quantity > 0) {
        this.$emit('update-quantity', item.product, quantity);
      } else {
        this.$emit('update-quantity', item.product, 1);
      }
    },
  },
  computed: {
    totalPrice() {
      return this.cartItems.reduce(
          (total, item) => total + item.product.price * item.quantity,
          0
      );
    },
  },
};
</script>

<style scoped>
.shopping-cart {
  width: 400px;
  height: 100%;
  background: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1050;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -400px;
  transition: right 0.3s ease;
}

.shopping-cart.open {
  right: 0;
}

.cart-header {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-body {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
}

.cart-footer {
  padding: 15px;
  border-top: 1px solid #ddd;
}

.close {
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
}

.close:hover {
  cursor: pointer;
}

.input-group {
  width: 120px;
}

.cart-item-info {
  flex-grow: 1;
}

.cart-item-controls {
  display: flex;
  align-items: center;
}

/* Стили для кнопки удаления */
.btn-link {
  font-size: 1.2rem;
}

.btn-link i {
  pointer-events: none;
}

.cart-item-actions {
  display: flex;
  align-items: center;
}

.cart-item-controls {
  display: flex;
  align-items: center;
}

.remove-button {
  margin-left: 15px;
}
</style>