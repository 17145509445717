<template>
  <div id="app">
    <div :class="['main-container', { 'cart-open': isCartOpen }]">
      <ProductList @add-to-cart="addToCart" />
      <ShoppingCart
          :cartItems="cart"
          :isOpen="isCartOpen"
          @remove-from-cart="removeFromCart"
          @update-quantity="updateQuantity"
          @checkout="proceedToCheckout"
          @close-cart="closeCart"
      />
    </div>
    <button class="btn btn-primary cart-toggle" @click="openCart">
      {{ cartMessage }}
    </button>
  </div>
</template>

<script>
import ProductList from '@/components/ProductList.vue';
import ShoppingCart from '@/components/ShoppingCart.vue';
import { products } from '@/data/products';

/* global FundraiseUp */

export default {
  components: {
    ProductList,
    ShoppingCart,
  },
  data() {
    return {
      cart: [],
      isCartOpen: false,
    };
  },
  computed: {
    totalItems() {
      return this.cart.reduce((total, item) => total + item.quantity, 0);
    },
    cartMessage() {
      if (this.totalItems === 0) {
        return 'Your cart is empty';
      } else {
        return `${this.totalItems} ${this.totalItems === 1 ? 'item' : 'items'} in your cart`;
      }
    },
  },
  methods: {
    addToCart(product) {
      const cartItem = this.cart.find((item) => item.product.id === product.id);
      if (cartItem) {
        cartItem.quantity++;
      } else {
        this.cart.push({ product, quantity: 1 });
      }
      this.saveCart();
      this.isCartOpen = true;
    },
    removeFromCart(product) {
      this.cart = this.cart.filter((item) => item.product.id !== product.id);
      this.saveCart();
      if (this.cart.length === 0) {
        this.isCartOpen = false;
      }
    },
    updateQuantity(product, quantity) {
      const cartItem = this.cart.find((item) => item.product.id === product.id);
      if (cartItem) {
        cartItem.quantity = quantity;
        if (cartItem.quantity < 1) {
          this.removeFromCart(product);
        }
        this.saveCart();
      }
    },
    proceedToCheckout() {
      // eslint-disable-next-line
      if (typeof FundraiseUp !== 'undefined') {
        const items = this.cart.map((item) => ({
          description: item.product.name,
          amount: item.product.price, // Сумма в долларах, без умножения на 100
          quantity: item.quantity,
          code: item.product.code,
        }));

        const totalAmount = items.reduce(
            (total, item) => total + item.amount * item.quantity,
            0
        );

        // eslint-disable-next-line
        FundraiseUp.openCheckout('FUNNWTWZCCW', {
          donation: {
            amount: totalAmount,
            currency: 'USD',
            recurring: 'once',
          },
          customFields: {
            shopping_cart_content: JSON.stringify(items),
          },
          config: {
            modifyAmount: false,
          },
        });
      } else {
        alert('Fundraise Up SDK не загружен.');
      }
    },
    saveCart() {
      const cartData = {
        version: 1,
        items: this.cart.map((item) => ({
          productId: item.product.id,
          quantity: item.quantity,
        })),
      };
      localStorage.setItem('cart', JSON.stringify(cartData));
    },
    loadCart() {
      const cartData = localStorage.getItem('cart');
      if (cartData) {
        try {
          const parsedData = JSON.parse(cartData);

          if (parsedData.version === 1 && Array.isArray(parsedData.items)) {
            const isValid = parsedData.items.every((item) => {
              return (
                  item &&
                  typeof item === 'object' &&
                  typeof item.productId === 'number' &&
                  typeof item.quantity === 'number'
              );
            });

            if (isValid) {
              this.cart = parsedData.items
                  .map((item) => {
                    const product = products.find((p) => p.id === item.productId);
                    if (product) {
                      return {
                        product,
                        quantity: item.quantity,
                      };
                    } else {
                      return null;
                    }
                  })
                  .filter((item) => item !== null);

              this.isCartOpen = this.cart.length > 0;
              return;
            }
          }

          throw new Error('Invalid cart data structure or version');
        } catch (error) {
          console.error('Error loading cart:', error);
          localStorage.removeItem('cart');
          this.cart = [];
          this.isCartOpen = false;
        }
      }
    },
    closeCart() {
      this.isCartOpen = false;
    },
    openCart() {
      this.isCartOpen = true;
    },
  },
  created() {
    this.loadCart();

    // eslint-disable-next-line
    if (typeof FundraiseUp !== 'undefined') {
      // eslint-disable-next-line
      FundraiseUp.on('donationComplete', (details) => {
        this.cart = [];
        this.saveCart();
        this.isCartOpen = false;
      });
    } else {
      console.error('Fundraise Up SDK не загружен.');
    }
  },
};
</script>

<style>
.cart-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.main-container {
  display: flex;
  transition: margin-right 0.3s ease;
}

.main-container.cart-open {
  margin-right: 400px;
}

@media (max-width: 768px) {
  .main-container.cart-open {
    margin-right: 0;
  }
}
</style>