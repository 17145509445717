<template>
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-3 mb-4" v-for="product in products" :key="product.id">
        <ProductCard :product="product" @add-to-cart="addToCart" />
      </div>
    </div>
  </div>
</template>

<script>
import { products } from '@/data/products';
import ProductCard from '@/components/ProductCard.vue';

export default {
  components: {
    ProductCard,
  },
  data() {
    return {
      products,
    };
  },
  methods: {
    addToCart(product) {
      this.$emit('add-to-cart', product);
    },
  },
};
</script>